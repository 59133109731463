<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="employeeAccounts.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :validatePermission="false"
    @save="onSave('TelemarketingTasksForCurrentUser')"
    @cancel="onCancelPage()"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'TelemarketingTasksForCurrentUserReminderCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'Reminders',
      breadcrumbs: [
        {
          text: 'Напоминания',
          route: { name: 'Reminders' },
        },
        {
          text: 'Создание напоминания',
        },
      ],
      pageHeader: 'Создание напоминания',
      initialForm: {
        accountId: null,
        whenRemind: null,
        comment: null,
        relatedObjectType: 'TelemarketingTasks',
        relatedObjectId: null,
      },
      form: {},
    };
  },
  computed: {
    ...mapState({
      employeeAccounts: (state) => state.common.employeeAccounts,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'accountId',
              label: 'Аккаунт',
              list: this.employeeAccounts.data,
            },
            {
              type: 'fullDateTime',
              key: 'whenRemind',
              label: 'Дата напоминания',
              attributes: {
                format: 'DD.MM.YYYY HH:mm',
                valueType: 'DD.MM.YYYY HH:mm:ss',
                timeTitleFormat: 'DD.MM.YYYY HH:mm',
                disabledDate: this.dateDisabled,
                disabledTime: this.timeToDisabled,
              },
            },
            {
              type: 'textarea',
              key: 'comment',
              label: 'Комментарий',
            },
            {
              type: 'text',
              key: 'relatedObjectId',
              label: '№ задания по телемаркетингу',
              disabled: true,
            },
          ],
        },
      ];
    },
  },

  created() {
    this.form.accountId = +localStorage.getItem('accountId');
    const { relationForm } = this.$route.params;

    if (relationForm) {
      this.form = {
        ...this.form,
        ...relationForm,
      };
    }

    this.getCommonList({
      name: 'EmployeeAccounts',
    });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
      createItem: 'editableList/createItem',
      getListforCommon: 'editableList/getListforCommon',
      getActualReminders: 'reminders/getActualReminders',
    }),

    dateDisabled(date) {
      const currentDate = new Date();

      return date < new Date(currentDate.setHours(0, 0, 0, 0));
    },

    timeToDisabled(date) {
      const currentDate = new Date();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();

      return date < new Date(currentDate.setHours(hours, minutes + 10, 0, 0));
    },

    onSave() {
      this.createItem({
        name: this.controllerName,
        payload: this.form,
      })
        .then((result) => {
          if (result.isSucceed) {
            this.getActualReminders();
            this.$router.go(-2);
            this.$notify({
              header: 'Напоминание добавлено.',
              type: 'success',
              timer: 5000,
            });
          }
        })
        .finally(() => {
          this.isLoadingSaveButton = false;
        });
    },

    onCancelPage() {
      this.$router.go(-2);
    },
  },
};
</script>
